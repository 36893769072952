@import "./style/variables.css";
body {
    font-family: 'Montserrat Regular', 'Montserrat', 'Roboto', 'Lato', 'FiraMonoRegular', sans-serif !important;
    overflow-x: hidden;
}

@font-face {
    font-family: "FiraMonoRegular";
    src: local("FiraMonoRegular"), url('./style/fonts/FiraMono-Regular.ttf') format("truetype");
}

h1, h2, h3, h4, h5 {
    color: var(--primary-color);
    transition: 0.5s ease;
}

p {
    color: var(--neutral-dark)!important;
}

h4 {
    font-size: 1.4rem!important;
}

.App {
    background-image: url("./style/img/kaart.png");
    background-size: cover;
    height: 100%;
}

a {
    color: var(--primary-color)!important;
    font-size: 1.1rem
}

a:hover {
    color: var(--primary-color-08)!important;
}

.ui.radio.checkbox {
    line-height: 1.5rem;
    transition: 0.1s ease-in-out
}

.ui.checkbox {
    line-height: 1.5rem!important;
    transition: 0.1s ease-in-out
}


/*
Semantic UI grid muudatused
*/

.ui.grid {
    margin: auto!important;
}

/*
View ehk keskmine riba
*/

.view {
    max-width: 80%;
    min-height: calc(100vh - 189px) !important;
    margin: auto;
    background-color: var(--background-color);
    box-shadow: var(--box-shadow);
}

/*
--------EU fund logo--------
*/

#fundLogo1 {
    position: absolute;
    width: 105px;
    padding: 5px;
    background-color: white;
    margin-left: 10px;
    margin-top: 10px;
}

#fundLogo2 {
    position: relative;
    margin-top: 20px;
    display: none;
}

#fundLogo2 img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 210px;
}

#fundLogo3 {
    text-align: center!important;
    margin-top: 30px;
    display: none;
}

#fundLogo3 img {
    width: 220px;
}

/*
--------Header/Banner--------
*/

.banner {
    height: 100px;
    background-color: var(--primary-color);
    box-shadow: var(--box-shadow-header);
    z-index: 30;
    position: relative;
}

.banner a {
    color: #fff!important;
    font-size: 1.4rem;
    line-height: 1.6rem
}
/* Carret */
div.right.aligned.two.wide.computer.four.wide.mobile.column > div > i {
    top: 14px;
    color: white;
    right: -5px;
    font-size: initial;
    opacity: 1;
}

.banner img {
    position: relative;
    top: 50%;
    left: -40px;
    transform: translateY(-50%);
    height: 64px;
    border-bottom: 3px solid transparent;
}

.banner img:hover {
    border-bottom: 3px solid white;
}

.banner .ui.equal.width.grid {
    height: 100%;
}

.banner .three.column.row {
    padding: 0;
}

.banner .ui.selection.dropdown {
    background-color: var(--primary-color)!important;
}

.banner div.center.aligned.column {
    padding: 0;
}

.logoHolder{
    width: 230px !important;
}

#appName {
    display: inline-block;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    border-bottom: 3px solid transparent;
}

#appName:hover {
    border-bottom: 3px solid white;
}

#appNameMobile {
    display: none;
}

.banner .ui.fluid.dropdown {
    background-color: transparent;
    border: transparent;
    text-align: center;
    color: white;
    top: 50%;
    transform: translateY(-50%);
}

.banner .ui.dropdown>.text {
    font-size: 1.6rem;
    margin-top: 6px;
    padding-bottom: 6px;
    border-bottom: 3px solid transparent;
}

.banner .ui.dropdown>.text:hover {
    border-bottom: 3px solid white;
}

.ui.selection.active.dropdown:hover {
    box-shadow: none!important;
}

.ui.dropdown .menu .selected.item, .ui.dropdown.selected {
    background-color: var(--background-color)!important;
}

.ui.selection.dropdown {
    background-color: var(--background-color)!important;
    border-radius: 10px!important;
    border-color: var(--primary-color)!important;
}

.languageSelection {
    width: 70px!important;
    margin: auto
}

.ui.selection.active.dropdown {
    border-color: var(--primary-color-08)!important;
}

.ui.selection.dropdown:focus {
    border-color: var(--primary-color-08)!important;
}

.ui.dropdown .menu {
    border-style: solid!important;
    border-color: var(--primary-color)!important;
    border-width: 1px!important;
    border-radius: 0px!important;
    background-color: white!important;
}

/*
--------Footer--------
*/

.appFooter {
    position: relative;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    min-height: 70px;
    margin-top: 0;
    background-color: var(--primary-color);
}

.appFooter p {
    color: #fff!important;
    font-size: 16px;
}

.appFooter .column {
    text-align: center!important;
    margin: auto;
    padding-bottom: 5px!important;
}

#mobileTitle {
    display: none;
}

#desktopTitle {
    display: block;
    margin-bottom: 0;
}

/*
--------Intro komponent--------
*/

.introText p {
    font-size: 1.3rem;
    color: var(--primary-color);
    padding: 10px 10px 0px 10px;
    text-align: justify;
}

.introText a {
    font-size: 1.3rem;
}

.button {
    padding-top: 5px;
    width: 100%;
}

.button .ui.animated.button {
    background-color: var(--background-color);
    border: 1px;
    border-style: solid;
    border-color: var(--primary-color);
    color: var(--primary-color);
    border-radius: 50px!important;
    transition: 0.5s ease;
}

.button .ui.animated.button.dummy {
    background-color: var(--background-color);
    border: 1px;
    border-style: solid;
    border-color: var(--negative);
    color: var(--negative)!important;
    transition: 0.5s ease;
}

/* hover inverts colors */

.button .ui.animated.button:hover {
    background-color: var(--secondary-color);
    color: var(--background-color)!important;
}

.button .ui.animated.button.dummy:hover {
    cursor: not-allowed;
    background-color: var(--negative);
    color: var(--background-color)!important;
}

.introText .instructions {
    margin-top: 40px;
    text-align: center;
    line-height: 1.4;
}

.introText .instructions div {
    display: inline-block;
    text-align: left;
}

/*
--------ComponentTypeChoice component--------
*/

.heading .ui.horizontal.divider {
    margin-top: 0;
    color: var(--primary-color);
    text-align: center;
    padding: 10px;
    padding-top: 20px;
}

.rightSideCTC h4 {
    font-size: 1.4rem;
    color: var(--primary-color);
}

/* radio button + checkbox text */

.ui.checkbox input.hidden+label {
    color: var(--primary-color);
    font-size: 1.4rem;
}
.checkboxStyledElement{
    color: var(--primary-color)!important;
    font-size: 1.4rem;
    margin-top: 20px;
}

/* div ID input */

.ui[class*="left icon"].input>input {
    background-color: transparent;
    border-color: var(--primary-color);
    color: var(--primary-color);
    font-size: 1.2rem;
    padding: 10px 5px 10px 5px;
}

/* qr icon */

.ui.icon.input>i.icon:not(.link) {
    color: var(--primary-color);
    font-size: 1.2rem;
}

.ui.input {
    width: 100%;
}

/* Radio button customization */

.ui.radio.checkbox label:before {
    width: 21px!important;
    height: 21px!important;
    background-color: var(--background-color);
    border-color: var(--primary-color)!important;
}

.ui.radio.checkbox label:after {
    position: absolute;
    left: 3px!important;
    top: 4px!important;
    transform: scale(0.8)!important;
    background-color: var(--primary-color)!important;
}

.ui.checkbox input:focus~label {
    color: var(--primary-color)!important;
}

/* Checkbox button customization */

.ui.checkbox input~label:before {
    width: 21px;
    height: 21px;
    background-color: var(--background-color)!important;
    border-color: var(--primary-color)!important;
}

.checkboxGroup .ui.checkbox input:checked~label:after {
    color: white!important;
    border-radius: 3px;
    background-color: var(--primary-color);
    width: 21px;
    height: 21px;
}

.checkboxGroup .ui.checkbox {
    display: block;
    margin-top: 15px;
}

.ui.checkbox:hover {
    transform: scale(1.01);
}

.ui.checkbox:hover input.hidden+label {
    color: var(--secondary-color);
}

.column.leftSideCTC {
    color: var(--primary-color);
    padding-left: 0!important;
}

.exampleContainer {
    padding: 0 1rem;
}

.buttonRow .column {
    text-align: center!important;
}

#searchBoxLocationButton {
    display: inline-block;
}
#searchBoxLocationButton .ui.button{
    padding: 5px 5px 5px 5px;
}

#arrow{
    margin: auto!important;
    transition: 0.2s ease-in-out;

}

.reverse{
    transform: rotate(180deg);
}

/* 
--------LocationDisplay component--------
*/

.rightSideLD .ui-input-text, .ui-input-search {
    margin-bottom: 10px;
}

.rightSideLD .ui.form {
    margin-top: 20px;
}

.rightSideLD p {
    margin-top: 20px;
}

.ui-listview-inset>.ui-li-static, .ui-listview-inset>.ui-li-divider, .ui-listview-inset>li>a.ui-btn {
    z-index: 1;
}

.attention {
    color: var(--negative);
    transition: 0.5s ease;
}
.inAds {
    height: 40px;
}
/* 
--------LocationDisplayAdvanced component--------
*/

.mapSizing {
    display: flex;
}

.mapSizing p {
    font-size: 1.1rem;
    margin-top: auto!important;
    margin-bottom: auto;
    padding-right: 5px;
}

.mapSizing .ui.input {
    width: 100px;
}

#dimensionsHeader {
    margin-bottom: 4px;
}

.warning {
    display: block;
    opacity: 0;
    margin-bottom: 3px;
    color: var(--negative)!important;
    transition: 0.2s ease;
}

/* 
--------SearchTypeChoice component--------
*/

.leftSideSTC h4 {
    text-align: center;
    font-size: 1.3rem;
}

.rightSideSTC h4 {
    text-align: center;
    font-size: 1.3rem;
}

.ui.segment {
    background-color: transparent!important;
}

.leftSideSTC .ui.form {
    padding-left: 21px;
}

.checkboxGroup .group {
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    border-color: var(--primary-color);
    margin: 10px;
    transition: 0.2s
}

.checkboxGroup .group_attention {
    border-style: solid;
    border-radius: 10px;
    border-width: 2.5px;
    border-color: var(--negative);
    margin: 10px;
    transition: 0.2s
}

.checkboxGroup .group .ui.checkbox {
    padding: 10px 10px 10px 10px;
    margin: 0;
}

.checkboxGroup .group_attention .ui.checkbox {
    padding: 10px 10px 10px 10px;
    margin: 0;
}

.checkboxGroup .group .tabbed {
    padding-left: 40px!important;
}

.checkboxGroup .group_attention .tabbed {
    padding-left: 40px!important;
}
.text-and-combo{
    display: flex;
    margin-bottom: 10px;
    padding: 0 10px;
}
.text-and-combo p{
    font-size: 1.4rem;
    color: var(--primary-color)!important;
    display: inline;
    margin-top: auto!important;
    margin-bottom: auto;
}

.text-and-combo .ui.fluid.dropdown {
    width: 200px;
    height: 38px;
    margin-top: auto;
    margin-bottom: auto;
}

.together {
    display: flex;
}

/* 
--------MapTypeChoice component--------
*/

.inputField {
    display: flex;
}

.inputField .ui.input {
    width: 300%;
    margin-right: 10px;
}

.ui.input>input {
    background-color: var(--background-color)!important;
    border-color: var(--primary-color)!important;
    border-radius: 5px!important;
    margin-top: 2px;
}

.ui.button {
    background-color: var(--background-color)!important;
    border: 1px!important;
    border-style: solid!important;
    border-color: var(--primary-color)!important;
    color: var(--primary-color)!important;
    border-radius: 50px!important;
}

.ui.button:hover {
    background-color: var(--secondary-color)!important;
    border-color: var(--primary-color)!important;
    color: var(--background-color)!important;
}

.ui.button:focus {
    background-color: var(--secondary-color-07)!important;
    border-color: var(--primary-color-08)!important;
    color: var(--background-color)!important;
}

.filterList .ui.label:last-child {
    background-color: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.filterList .ui.label>.close.icon, .ui.label>.delete.icon {
    margin-left: 0!important;
    color: var(--primary-color);
}

.filterList .item {
    word-wrap: break-word;
}

.radioGroup {
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    border-color: var(--primary-color);
    margin: 10px;
    padding: 10px;
    transition: 1s;
}

/*
--------LocationDisplay component--------
*/

.pointButtons {
    display: none;
}

.pointButtons .ui.button {
    margin-top: 6px;
    display: block;
}

input {
    background-color: transparent;
    border-color: var(--primary-color);
    border-radius: 5px;
    color: var(--primary-color);
    border-width: 1px;
    padding: 4px;
}

input:focus {
    border-color: var(--primary-color-08)!important;
    background-color: rgba(43, 255, 0, 0.048);
    border: 1px;
    border-style: solid;
    outline: none;
}

button {
    background-color: var(--background-color)!important;
    border: 1px!important;
    border-style: solid!important;
    border-color: var(--primary-color)!important;
    color: var(--primary-color)!important;
    border-radius: 50px!important;
}

button:hover {
    cursor: pointer;
    background-color: var(--secondary-color)!important;
    border-color: var(--primary-color)!important;
    color: var(--background-color)!important;
}

button:focus {
    background-color: var(--secondary-color-07)!important;
    border-color: var(--primary-color-08)!important;
    color: var(--background-color)!important;
}

.mapPointsList > div > input {
    width: 100%;
    height: 33.25px;
    margin-top: 3px;
}
.mapPointsList > div > button {
    width: 33.25px;
    margin-top: 3px;
    margin-left: 2px;
}

/*
--------LocationDisplayPreview component--------
*/

.example {
    position: relative;
    padding: 50px 0 50px 0;
}

.example div {
    margin: auto;
}

/*
--------CodeGeneratorResult component--------
*/

.code {
    display: block;
    background-color: white;
    padding: 5px;
    border: 0px;
    border-radius: 7px;
    border-style: solid;
    border-color: var(--primary-color);
    white-space: pre-line;
    overflow-wrap: break-word;
    font-size: 1rem;
    font-family: 'FiraMonoRegular', sans-serif;
}

.copy i.icon {
    float: right;
    color: var(--primary-color);
    font-size: 1.3rem;
}

.copy i.icon.outline:hover {
    color: var(--primary-color-08);
    cursor: pointer;
    transition: 0.3s;
}

/* popup message when clicking on copy */
.ui.popup{
    border-radius: 10px!important;
}

.desktop-hidden {
    display: none!important;
}

@media only screen and (max-width: 1000px) {
    .view {
        max-width: 100%;
    }
    .banner p {
        font-size: 1.2rem;
    }
    .introText p {
        font-size: 1.05rem;
    }
    .introText a {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 1300px) {
    .view {
        max-width: 100%;
    }
    .introText p {
        font-size: 1.2rem;
    }
    .introText a {
        font-size: 1.2rem;
    }
    #fundLogo1 {
        display: none;
    }
    #fundLogo2 {
        display: block;
    }
}

/*
--------Mobile specific changes--------
*/

@media only screen and (max-width: 767px) {
    .view {
        max-width: 100%;
    }
    .introText p {
        font-size: 1.3rem;
    }
    .banner img {
        left: -110px;
    }
    #appNameMobile {
        display: block;
        top: 50%;
        position: relative;
        transform: translate(7%, -50%);
        font-size: 1rem!important;
    }
    #appName {
        display: none;
    }
    #fundLogo2 {
        display: none;
    }
    #fundLogo3 {
        display: block
    }
    #mobileTitle {
        display: block;
    }
    #mobileTitle p{
        text-align: center;
    }
    #desktopTitle {
        display: none;
    }
    .mobile-hidden {
        display: none;
    }
    .ui.selection.dropdown .menu {
        max-height: 300px!important;
    }
    /* Component Type Choice  example div*/
    #root > div > div.view > div > div.ui.centered.stackable.two.column.grid > div > div.column.leftSideCTC {
        padding: 0!important;
    }
    .leftSideCTC .exampleContainer {
        border-radius: 0;
        padding: 0;
        margin-left: 10px;
        margin-right: 10px;
    }
    .example {
        overflow:scroll;
        padding-left: 25px;
        padding-right: 25px;
    }
}